<template>
  <div v-if="isShowSkeletonLoading || games?.length" class="group-games-adaptive">
    <client-only>
      <skeleton-preloader-group-games v-if="isShowSkeletonLoading" :is-mobile="isMobile" />
    </client-only>

    <template v-if="games?.length">
      <div :class="['group-games-adaptive__list', { 'group-games-adaptive__list_is-mobile': isMobile }]">
        <atomic-cat-heading :icon="titleIcon">
          {{ title }}
        </atomic-cat-heading>

        <span v-if="showAllBtn" class="group-games-adaptive__btn-show-all" @click="openGames">
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
        </span>

        <list-grid v-if="isMobile" class="group-games-adaptive__mobile-list" :items="games" :meta="pageMeta" />

        <template v-else>
          <button-arrows
            v-if="showArrowButtons"
            :prev-disabled="prevDisabled"
            :next-disabled="nextDisabled"
            @click-action="clickAction"
          />

          <div
            ref="scrollContainer"
            class="group-games-adaptive__items"
            :class="{ 'disabled-scroll-block': !games.length }"
            @scroll="scrollHandler"
          >
            <card-base v-for="(game, gameIndex) in games" :key="gameIndex" :game-info="game" />

            <div ref="loadMore" class="group-games-adaptive__load-more" @inview="moreGames" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
  import { watchDebounced } from '@vueuse/core';

  import { urlFormat } from '@skeleton/utils/routerUtils';
  import { scrollBodyToTop } from '@skeleton/utils/scrollUtils';

  import type { ICollection, IGame, IGamesRequest, IPaginationMeta } from '@skeleton/core/types';

  interface IProps {
    category?: ICollection;
    subTitle?: boolean;
    showAllBtn?: boolean;
    maxItems?: number;
    searchValue?: string;
    showArrows?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    maxItems: 9,
    searchValue: '',
    showArrows: true,
  });

  const isShowSkeletonLoading = ref(true);

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, gameCategoriesObj, headerCountry } =
    storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { getContent } = useProjectMethods();
  const { getGames } = useGameCollectionStoreStore();

  const games = ref<IGame[]>([]);
  const pageMeta = ref<IPaginationMeta>();

  const defaultRequestParams = computed<IGamesRequest>(() => ({
    collectionId: props.category?.id,
    perPage: props.maxItems,
    countries: headerCountry.value ? [headerCountry.value] : undefined,
    sortBy: 'default',
    sortOrder: 'asc',
    category: props.category?.identity,
    name: props.searchValue.length ? props.searchValue : undefined,
  }));

  const OFFSET_MARGIN = 20;

  const titleIcon = computed(() =>
    props.category?.identity ? gameCategoriesObj.value[props.category?.identity?.toLowerCase()]?.icon : ''
  );

  const title = computed(() =>
    props.category
      ? gameCategoriesObj.value[props.category.identity]?.label || props.category.name || props.category.identity
      : ''
  );

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value =
      scrollWidth < scrollLeft + offsetWidth + OFFSET_MARGIN &&
      scrollWidth > scrollLeft + offsetWidth - OFFSET_MARGIN &&
      pageMeta.value?.page === pageMeta.value?.totalPages;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  const loadGames = async () => {
    const data = await getGames(defaultRequestParams.value);
    games.value = data.games;
    pageMeta.value = data.pageMeta;
    isShowSkeletonLoading.value = false;
  };

  const emit = defineEmits(['initialLoad']);
  const observerLoadMore = ref();
  const { initObserver, localizePath } = useProjectMethods();
  const scrollContainer = ref();
  const loadMore = ref();
  const prevDisabled = ref(true);
  const nextDisabled = ref(true);
  const showArrowButtons = ref<boolean>(props.showArrows);

  const moreGames = async (): Promise<void> => {
    if (pageMeta.value?.page === pageMeta.value?.totalPages) return;

    const gamesResponse = await getGames({
      ...defaultRequestParams.value,
      page: pageMeta.value ? pageMeta.value.page + 1 : 1,
    });

    games.value = games.value.concat(gamesResponse.games);
    pageMeta.value = gamesResponse.pageMeta;
  };

  const initializeObserver = async () => {
    observerLoadMore.value = initObserver({
      settings: { root: scrollContainer.value, rootMargin: '90%', threshold: 0 },
    });

    try {
      if (games?.value?.length && loadMore.value) {
        observerLoadMore.value.observe(loadMore.value);
      }
    } catch (error) {
      console.error('Error initializing observer:', error);
    }

    await nextTick();
    emit('initialLoad');

    if (props.showArrows) {
      scrollHandler();
      showArrowButtons.value = props.showArrows && (!prevDisabled.value || !nextDisabled.value);
    }
  };

  watch(
    () => props.category,
    async category => {
      if (!category) return;
      await loadGames();
      await initializeObserver();
    },
    { immediate: true }
  );

  onMounted(async () => {
    if (props.category) {
      await initializeObserver();
    }
  });

  onBeforeUnmount(() => {
    if (loadMore.value && observerLoadMore.value) {
      observerLoadMore.value.unobserve(loadMore.value);
    }
  });

  const openGames = async (): Promise<void> => {
    if (props.category?.identity) {
      scrollBodyToTop();
      await navigateTo({
        path: localizePath(`/games/${urlFormat(props.category.identity)}`),
      });
    }
  };

  watchDebounced(() => props.searchValue, loadGames, { debounce: 500, maxWait: 1000 });
</script>

<style src="~/assets/styles/components/group/games-adaptive.scss" lang="scss" />
